<template>
	<div class="pui-form">
		<equipamiento-modals :modelName="modelName"></equipamiento-modals>
		<pui-form-header v-if="modelLoaded" :showHeader="!isCreatingElement">
			<documentoaduanero-form-header :modelPk="modelPk"></documentoaduanero-form-header>
		</pui-form-header>
		<v-tabs v-model="tabmodel" class="ml-3 mb-3" slider-color="primary" show-arrows>
			<v-tab :key="0" :href="'#maintab'">{{ $t('documentoaduanero.maintab') }}</v-tab>
			<v-tab :key="1" :href="'#partidas'" v-if="!isCreatingElement">{{ $t('documentoaduanero.partidastab') }}</v-tab>
		</v-tabs>
		<v-tabs-items v-model="tabmodel">
			<v-tab-item :key="0" lazy :value="'maintab'">
				<v-layout row wrap class="pui-form-layout">
					<v-flex xs12>
						<pui-field-set :title="getTitleDatosIdentificativos" highlighted class="pt-3">
							<v-layout row wrap>
								<v-flex xs4 md2 v-if="!isCreatingElement">
									<pui-number-field
										:id="`iddocumento-documentoaduanero`"
										v-model="model.iddocumento"
										:label="$t('documentoaduanero.iddocumento')"
										disabled
										toplabel
										integer
										min="0"
										max="9999999999"
									></pui-number-field>
								</v-flex>

								<v-flex xs4 md2>
									<pui-text-field
										:id="`numdocumento-documentoaduanero`"
										v-model="model.numdocumento"
										:label="$t('documentoaduanero.numdocumento')"
										:disabled="formDisabled"
										toplabel
										maxlength="23"
									></pui-text-field>
								</v-flex>
								<v-flex xs2>
									<pui-text-field
										:id="`tipodoc-documentoaduanero`"
										v-model="model.tipodoc"
										:label="$t('documentoaduanero.tipodoc')"
										:disabled="formDisabled"
										toplabel
										maxlength="3"
									></pui-text-field>
								</v-flex>
							</v-layout>
						</pui-field-set>
					</v-flex>
					<v-flex xs12>
						<pui-field-set :title="getTitleDatos" class="pt-3">
							<v-layout row wrap>
								<v-flex xs3 md2>
									<pui-number-field
										:id="`pesobruto-documentoaduanero`"
										v-model="model.pesobruto"
										:label="$t('documentoaduanero.pesobruto')"
										:disabled="formDisabled"
										toplabel
										integer
										decimals="0"
										min="0"
										max="9999999999"
									></pui-number-field>
								</v-flex>
								<v-flex xs3 md2>
									<pui-number-field
										:id="`numbultos-documentoaduanero`"
										v-model="model.numbultos"
										:label="$t('documentoaduanero.numbultos')"
										:disabled="formDisabled"
										toplabel
										integer
										decimals="0"
										min="0"
										max="9999999999"
									></pui-number-field>
								</v-flex>
								<v-flex xs4 md2>
									<pui-text-field
										:id="`taric-documentoaduanero`"
										v-model="model.taric"
										:label="$t('documentoaduanero.taric')"
										:disabled="formDisabled"
										toplabel
										maxlength="10"
									></pui-text-field>
								</v-flex>
								<v-flex xs2 md1>
									<pui-text-field
										:id="`estado-documentoaduanero`"
										v-model="model.estado"
										:label="$t('documentoaduanero.estado')"
										:disabled="formDisabled"
										toplabel
										maxlength="2"
									></pui-text-field>
								</v-flex>
							</v-layout>
							<v-layout row wrap>
								<v-flex xs6 md4>
									<pui-date-field
										:id="`fechalsp-documentoaduanero`"
										v-model="model.fechalsp"
										:label="$t('documentoaduanero.fechalsp')"
										:disabled="formDisabled"
										toplabel
										time
									></pui-date-field>
								</v-flex>
								<v-flex xs6 md4>
									<pui-date-field
										:id="`fecdocumento-documentoaduanero`"
										v-model="model.fecdocumento"
										:label="$t('documentoaduanero.fecdocumento')"
										:disabled="formDisabled"
										toplabel
										time
									></pui-date-field>
								</v-flex>
							</v-layout>
							<v-layout row wrap>
								<v-flex xs3 md2>
									<pui-number-field
										:id="`numequip-documentoaduanero`"
										v-model="model.numequip"
										:label="$t('documentoaduanero.numequip')"
										:disabled="formDisabled"
										toplabel
										integer
										decimals="0"
										min="0"
										max="9999999999"
									></pui-number-field>
								</v-flex>
								<v-flex xs3 md2>
									<pui-number-field
										:id="`numpartidas-documentoaduanero`"
										v-model="model.numpartidas"
										:label="$t('documentoaduanero.numpartidas')"
										:disabled="formDisabled"
										toplabel
										integer
										decimals="0"
										min="0"
										max="9999999999"
									></pui-number-field>
								</v-flex>
								<v-flex xs2 md2>
									<pui-text-field
										:id="`destino-documentoaduanero`"
										v-model="model.destino"
										:label="$t('documentoaduanero.destino')"
										:disabled="formDisabled"
										toplabel
										maxlength="5"
									></pui-text-field>
								</v-flex>
								<v-flex xs3 md3>
									<pui-text-field
										:id="`ubicacion-documentoaduanero`"
										v-model="model.ubicacion"
										:label="$t('documentoaduanero.ubicacion')"
										:disabled="formDisabled"
										toplabel
										maxlength="10"
									></pui-text-field>
								</v-flex>
							</v-layout>
							<v-layout row wrap>
								<v-flex xs6 md4>
									<pui-date-field
										:id="`fecalta-documentoaduanero`"
										v-model="model.fecalta"
										:label="$t('documentoaduanero.fecalta')"
										:disabled="formDisabled"
										toplabel
										time
									></pui-date-field>
								</v-flex>
								<v-flex xs6 md4>
									<pui-date-field
										:id="`fecevento-documentoaduanero`"
										v-model="model.fecevento"
										:label="$t('documentoaduanero.fecevento')"
										:disabled="formDisabled"
										toplabel
										time
									></pui-date-field>
								</v-flex>
								<v-flex xs6 md1>
									<pui-text-field
										:id="`evento-documentoaduanero`"
										v-model="model.evento"
										:label="$t('documentoaduanero.evento')"
										:disabled="formDisabled"
										toplabel
										maxlength="2"
									></pui-text-field>
								</v-flex>
								<v-flex xs6 md1>
									<pui-text-field
										:id="`motevento-documentoaduanero`"
										v-model="model.motevento"
										:label="$t('documentoaduanero.motevento')"
										:disabled="formDisabled"
										toplabel
										maxlength="2"
									></pui-text-field>
								</v-flex>
							</v-layout>
						</pui-field-set>
						<!-- MINI AUDIT -->
						<pui-form-mini-audit class="pl-2" :model="model" v-if="!isCreatingElement && false"></pui-form-mini-audit>
					</v-flex>
				</v-layout>
			</v-tab-item>
			<v-tab-item :key="1" :value="'partidas'" lazy v-if="!isCreatingElement && !isModalDialog">
				<pui-master-detail
					componentName="partida-grid"
					:parentModelName="modelName"
					:parentPk="pk"
					:parentPkChildFk="{ iddocumento: 'iddocumento' }"
					:modalDialog="isModalDialog"
					:formDisabled="true"
				></pui-master-detail>
			</v-tab-item>
		</v-tabs-items>
		<!-- Page Footer -->
		<pui-form-footer v-if="!isModalDialog">
			<pui-form-footer-btns
				:formDisabled="formDisabled"
				:saveDisabled="saving"
				:saveAndNew="saveAndNew"
				:saveAndUpdate="saveAndUpdate"
				:save="save"
				:back="back"
			></pui-form-footer-btns>
		</pui-form-footer>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import documentoaduaneroActions from './DocumentoaduaneroActions';

export default {
	name: 'documentoaduanero-form',
	mixins: [PuiFormMethodsMixin],

	components: {},
	data() {
		return {
			modelName: 'documentoaduanero',
			tabmodel: 'maintab',
			actions: documentoaduaneroActions.formActions
		};
	},
	methods: {
		afterGetData() {
			const thisModel = this.$store.getters.getModelByName(this.modelName);
			if (thisModel) {
				if (thisModel.tabFromAction) {
					this.tabmodel = thisModel.tabFromAction;
					delete thisModel.tabFromAction;
				}
			}
		}
	},
	computed: {
		getTitleDatosIdentificativos() {
			return this.$t('form.documentoaduanero.fieldset.ids');
		},
		getTitleDatos() {
			return this.$t('form.documentoaduanero.fieldset.dt');
		}
	},
	created() {}
};
</script>
